import { FunctionComponent } from 'react';
import { MdOutlineClose } from 'react-icons/md';


interface IModalProps {
    children: React.ReactNode,
    onClose: () => void,
    sideModal?: boolean,

    /**
     * Specify CSS classes to be added after the defaults on the centered modal element.
     * This has no effect on the backdrop or the root container.
     * Because they are added last, they will generally supercede the defaults,
     * barring any additional specifity in the CSS definitions.
     */
    className?: string,

    height?: string
    showOnTop?: boolean
    zIndex?: string
}

type TModalComponent = FunctionComponent<Readonly<IModalProps>>;

const ModalWrapper: TModalComponent = (props: IModalProps) => {
    const { children, onClose, sideModal = false, height, showOnTop, zIndex, className } = props;

    return (
        <div className={`fixed ${showOnTop ? 'z-40' : 'z-30'} ${zIndex} h-screen top-0 left-0 flex items-center ${sideModal ? 'justify-end' : 'justify-center'} w-full `}>
            <button
                className='h-screen w-full absolute z-20 bg-gray'
                aria-label='Close modal'
                onClick={onClose}
            />
            <div className={`${sideModal ? 'h-screen py-6 w-full lg:w-2/5 lg:max-w-md' : 'min-h-3/4 md:min-h-3/5 max-h-[90vh] w-96 p-6 rounded-md'} ${height ? 'h-fit' : '' } bg-card/80 backdrop-blur-xl shadow-2xl md:w-3/5 lg:max-w-2xl z-50 overflow-y-auto relative ${className}`}>
                <button onClick={onClose} className='absolute z-50 right-5 top-5 cursor-pointer px-6'>
                    <span aria-hidden="true">
                        <MdOutlineClose size={20}/>
                    </span>
                    <span className="sr-only">
                        Close modal
                    </span>
                </button>

                { children }
            </div>
        </div>
    )
}

export default ModalWrapper;
