// MSAL imports
import { PublicClientApplication, EventType } from "@azure/msal-browser";
import { msalConfig, loginRequest, b2cPolicies } from './azureAdB2cServiceConfig';

export const msalInstance = new PublicClientApplication(msalConfig);
// Default to using the first account if no account is active on page load
if (!msalInstance.getActiveAccount() && msalInstance.getAllAccounts().length > 0) {
  // Account selection logic is app dependent. Adjust as needed for different use cases.
  msalInstance.setActiveAccount(msalInstance.getAllAccounts()[0]);
}

// Optional - This will update account state if a user signs in from another tab or window
msalInstance.enableAccountStorageEvents();

msalInstance.addEventCallback((event: any) => {
  if (event.eventType === EventType.LOGIN_SUCCESS && event.payload.account) {
    const account = event.payload.account;
    localStorage.removeItem('operations_access_token');
    msalInstance.setActiveAccount(account);
  }
  else if (event.eventType === EventType.LOGIN_FAILURE) {
    if (event.error.errorMessage.indexOf("AADB2C90091") > -1) {
      msalInstance.loginRedirect(loginRequest);
    }
  }
  else if(event.eventType==EventType.ACQUIRE_TOKEN_FAILURE && event.error.name==='InteractionRequiredAuthError'){
    msalInstance.logoutRedirect();
  }
});


function selectAccount() {

  /**
   * See here for more information on account retrieval: 
   * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-common/docs/Accounts.md
   */

  const currentAccounts = msalInstance.getAllAccounts();

  if (currentAccounts.length < 1) {
    localStorage.clear();
    msalInstance.loginRedirect(loginRequest)
  } else if (currentAccounts.length > 0) {
      /**
       * Due to the way MSAL caches account objects, the auth response from initiating a user-flow
       * is cached as a new account, which results in more than one account in the cache. Here we make
       * sure we are selecting the account with homeAccountId that contains the sign-up/sign-in user-flow, 
       * as this is the default flow the user initially signed-in with.
       */
      const accounts = currentAccounts.filter((account: any) => {
        const cond1 = account.homeAccountId
          .toUpperCase()
          .includes(b2cPolicies.names.signUpSignIn.toUpperCase())
        ;

        const cond2 = account.idTokenClaims.iss
          .toUpperCase()
          .includes(b2cPolicies.authorityDomain.toUpperCase())
        ;

        const cond3 = account.idTokenClaims.aud === msalConfig.auth.clientId;
        return cond1 && cond2 && cond3;
      });

      if (accounts.length > 1) {
          // localAccountId identifies the entity for which the token asserts information.
          if (accounts.every(account => account.localAccountId === accounts[0].localAccountId)) {
              // All accounts belong to the same user
              msalInstance.setActiveAccount(accounts[0]);
          } else {
            msalInstance.logoutRedirect();
          };
      } else if (accounts.length === 1) {
        msalInstance.setActiveAccount(accounts[0]);
      }
  } 
  // else if (currentAccounts.length === 1) {
  //   msalInstance.setActiveAccount(msalInstance.getAllAccounts()[0]);
  // }
}
