import React, {  useEffect, useState } from 'react'
import Button from '../../ui/Button'
import Loader from '../../ui/Loader'
import DetailsCardItemsList from './DetailsCardItems'
import { toast } from 'react-toastify'
import { useSearchParams } from 'react-router-dom'
import { businessInfoToUpdate, companyInfoToUpdate } from '../../../../utils/helperFunctions/businessInfoToUpdate'
import { useUpdateApplicationDetailsMutation } from '../../../hooks/useApplicationDetailsQuery'
import { setValueAtNamepath } from '@/utils/helperFunctions/objects/namepaths'

const EditSingleApplicationDetail = React.lazy(() => import('../../modals/applications/EditApplicationDetailsModal'))

const keysToOmitFromDisplay = [
	'identificationDocumentUrl', 'IdentificationDocumentUrl',
	// 'companyAddress', 'postalAddress', 'residentialAddress',
	'passportPhotographUrl', 'signatureUrl',
	'SignatureUrl', 'IdentificationDocumentUrl',
	'PassportPhotographUrl',
	'fileNameUrlPairs',
	// 'BusinessAddress', 'businessAddress', 'financialRecordKeeperAddress',
	'trademarkClassifications', 'representationUrl',
	'passportDataPageUrl', 'certificateUrl',
	'companyRegistrationCertificateUrl',
	'birthCertificateUrl', 'nonCriminalRecordDocumentUrl',
	'proprietorInformation', 'BankAccountOpeningDetails',
	'ProprietorInformation',
	// 'PostalAddress', 'ResidentialAddress',
	'CompanyProfile',
	'DirectorInformation', 'SignatoryInformation',
	'UtilityBillUrl', 'ReferrFormUrl',
	'proprietorIDUrl', 'businessInformation',
	'countryTrademarkClassifications',
	'proficiencyCertificateUrl',
]

const keysWithNestedInfo = [
	'postalAddress', 'residentialAddress',
	'PostalAddress', 'ResidentialAddress',
	'BusinessAddress', 'companyAddress',
	'businessAddress', 'financialRecordKeeperAddress',
	'individualPartner', 'corporatePartner',
	'IndividualPartner', 'CorporatePartner',
]


interface DetailsCardProps {
	title: string,
	cardData: any,
	applicationDetails: any,
	nested?: boolean,
	edit?: boolean,
	listItemIndex?: number,
}


function DetailsCard(props: DetailsCardProps) {
	const { title, applicationDetails, listItemIndex } = props;
	const { nested = false, edit = false } = props;

	const [cardDataState, setCardDataState] = useState(props.cardData);
	const [editModalVisible, setEditModalVisible] = useState<boolean>(false);
	const [namepathToEdit, setNamepathToEdit] = useState('');

	const [searchParams] = useSearchParams();


	useEffect(() => {
		setCardDataState(props.cardData);
	}, [props.cardData]);
	

	const launchEditModal = (namepath: string) => {
		if (!edit) return;

		setNamepathToEdit(namepath);
		setEditModalVisible(!editModalVisible);
	}

	const updateApplicationDetailsMutation = useUpdateApplicationDetailsMutation({})

	const handleUpdateApplicationDetails = () => {
		let newDetails = applicationDetails;

		if (title === 'Company Information') {
			newDetails = companyInfoToUpdate({ data: newDetails, updatedData: cardDataState})
		}
		
		if (title === 'Business Information') {
			newDetails = businessInfoToUpdate(applicationDetails, cardDataState, newDetails)

			const natureOfBusinessMissing = !newDetails.cacNatureOfBusiness
				|| !newDetails.cacNatureOfBusinessId
				|| !newDetails.cacSpecificNatureOfBusiness
				|| !newDetails.cacSpecificNatureOfBusinessId
			;

			if (natureOfBusinessMissing) {
				toast.warning('Please enter the missing nature of business details');
				return;
			}
		}

		if (title === 'Proprietor Information') {
			const isLowerCase = !!newDetails.proprietorInformation;
			const keys = isLowerCase
				? ['proprietorInformation', 'soleProprietor', 'partners']
				: ['ProprietorInformation', 'SoleProprietor', 'Partners']
			;
			const [proprietorInfoKey, soleProprietorKey, partnersKey] = keys;

			console.log({cardDataState});
			if (cardDataState.partnerShipType) {
				if (!listItemIndex) {
					toast.warning('Error in frontend code: DetailsCard could not find listItemIndex for the edited partner.');
					console.error('DetailsCard requires an index for the specific partner whose info should be edited.');
					return;
				}
				newDetails[proprietorInfoKey][partnersKey][listItemIndex] = cardDataState;
			} else {
				newDetails[proprietorInfoKey][soleProprietorKey] = cardDataState;
			}
		}

		if (!searchParams.get('companyId')) {
			toast.warning('Company Id is not available', {
				toastId: 'company Id unavailable',
			});
			return;
		}

		console.log({newDetails});
		updateApplicationDetailsMutation.mutate(newDetails);
	}

	const displayDeciders = {
		keysToOmitFromDisplay,
		keysWithNestedInfo,
	}

	const cardInfo = { title, editable: edit };

	const onCloseEditModal = (newValue?: string) => {
		setEditModalVisible(false);

		console.log({ newValue });
		if (newValue) {
			console.log('Setting new...');
			const newCardDataState = { ...cardDataState };
			setValueAtNamepath(newCardDataState, newValue, namepathToEdit);
			setCardDataState(newCardDataState);
		} else {
			console.log('Skipped setting');
			setCardDataState({ ...cardDataState });
		}
	}

	return <>
		<EditSingleApplicationDetail
			onClose={onCloseEditModal}
			applicationDetails={applicationDetails}
			cardData={cardDataState}
			open={editModalVisible}
			namepath={namepathToEdit}
			key={namepathToEdit}
		/>

		<div className={`${edit ? 'border rounded-md px-5 md:px-10 pb-10 my-5' : 'border-t '} border-card`}>
			{edit && !applicationDetails?.cacNatureOfBusiness || !applicationDetails?.cacSpecificNatureOfBusiness || !applicationDetails?.cacNatureOfBusinessId || !applicationDetails?.cacSpecificNatureOfBusinessId &&
				<div className='rounded-md mt-5 bg-lightRed text-center py-4'>
					<p className='text-red'>
						One or more Nature of Business data is missing,
						application update will not be possible.
					</p>
				</div>
			}

			<div className={'flex flex-col xl:flex-row lg:w-full justify-between gap-10 xl:gap-0   py-10'}>
				<div className='lg:w-2/5'>
					<h3 className='font-semibold'>
						{title}
					</h3>
					<p className='opacity-75'>
						Use this to file the application
					</p>
				</div>
				<div className=' lg:w-3/5'>
					<div className='grid md:grid-cols-2 gap-5'>
						<DetailsCardItemsList
							displayDeciders={displayDeciders}
							nested={nested}
							launchEditModal={launchEditModal}
							cardInfo={cardInfo}
							cardDataState={cardDataState}
						/>
					</div>
				</div>
			</div>

			{edit ? (
				<div className='flex justify-end'>
					<Button onClick={handleUpdateApplicationDetails}>
						{updateApplicationDetailsMutation.isLoading ? (
							<Loader color='stroke-white'/>
						) : `Update ${ title }`}
					</Button>
				</div>
			) : null}
		</div>
	</>;
}

export default DetailsCard;
