import type { InputHTMLAttributes } from 'react';


type BaseInputProps = Omit<
	InputHTMLAttributes<HTMLInputElement>,
	'value'
>;

type InputValueBaseType = InputHTMLAttributes<HTMLInputElement>['value'];

interface ITextInput extends BaseInputProps {
	type?: string,
	placeholder?: string,
	icon?: JSX.Element,

	/**
	 * @deprecated This is a nested component, and there is no clarity on where a custom className would be applied.
	 * Hence, you should not use this, considering it's inherent ambiguity.
	 * If you need to constrain the position of the input, wrap it in a container and style the container.
	 * If you need to customize the appearance of the input, create an enum type "variant" prop and switch
	 * between predefined classes based on the value passed in the variant prop.
	 */
	className?: string,

	errors?: any,

	label?: string,
	name: string,
	value: InputValueBaseType | boolean | null,
}

const Input = (props: ITextInput) => {
	const { errors, icon, label, className, ...htmlInputProps } = props;

	const { id: inputId, type = 'text' } = htmlInputProps;

	const errorExists = errors?.[htmlInputProps.name] ?? errors?.message;
	const borderStyle = errorExists ? 'border-red' : 'border-card';

	if (!label) console.warn('All inputs must have a label. <Input /> was rendered without a label. This is an error and should be fixed.');

	return (
		<div className={type === 'checkbox' ? 'flex gap-3 justify-end items-center flex-row-reverse' : ''}>
			{!label ? null : (
				<label htmlFor={inputId} className='text-sm'>
					{label}
				</label>
			)}

			<div className={`${borderStyle} flex border bg-card rounded-md justify-between mt-2 mb-1`}>
				<input
					{...htmlInputProps}
					value={htmlInputProps.value?.toString() ?? undefined}
					className={[
						className,
						'h-full w-full text-sm outline-teal grow py-3 px-3',
					].join(' ')}
					type={type}
				/>

				{icon ? (
					<span className='h-full p-3'>
						{icon}
					</span>
				) : null}
			</div>

			{errors ? (
				<p className='text-red text-sm'>
					{errors[htmlInputProps.name]?.message ?? errors?.message}
				</p>
			) : null}
		</div>
	);
};

export default Input;
