import React from 'react';
import Input from './new/TextInput';

type TProps = Parameters<typeof Input>[0];

const LegacyInput = (props: TProps, ref: React.LegacyRef<any>) => {
	return <Input {...props} />
}

export default React.forwardRef(LegacyInput);
