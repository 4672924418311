import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { IoIosInformationCircleOutline } from 'react-icons/io'
import { toast } from 'react-toastify';
import dayjs from 'dayjs';
import LocalizedFormat from 'dayjs/plugin/localizedFormat'
import { fetchUserById } from '../../../api/Info';
import Badge from '../../ui/Badge';
import { CompleteServiceProps, QueryHelp, ServiceTimelinesInterface } from '../../../../utils/typings/Applications';
import { addSpaceToTitle } from '../../../../utils/constant';
import Loader from "../../ui/Loader"
import { DetailsInterface, FormDefinitionProps, IDInterface } from '../../../../utils/typings/Api';
import { fetchFlagHistory, initializeApplication, markAsPaid } from '../../../api/Applications';
import Tippy from '@tippyjs/react';
import DetailsCard from './DetailsCard';
import { BsFlag } from 'react-icons/bs';
import { MdError } from "react-icons/md";
import ViewFlagHistoryModal from '../../modals/applications/ViewFlagHistoryModal';
import { useApplicationDetailsData, useApplicationInfoData } from '../../../hooks/useApplicationDetailsQuery';
import { useServiceTimelineData } from '../../../hooks/useServiceTimelineQuery';
import { useApplicationDocumentsData } from '../../../hooks/useDocumentsQuery';

const Assign = React.lazy(() => import("../../modals/applications/AssignModal"))
const PreviewTable = React.lazy(() => import("./PreviewTable"))
const ErrorComponent = React.lazy(() => import("../../shared/ErrorComponent"))
const NoData = React.lazy(() => import("../../shared/NoData"))
const Button = React.lazy(() => import("../../ui/Button"))
const PrefillDocumentsModal = React.lazy(() => import('../../modals/applications/PreFilledDocumentsModal'))
const PreviewDocumentModal = React.lazy(async () => ({default: (await import('../../modals/applications/PreviewDocumentModal')).PreviewDocumentModalV2 }));
const ToggleFlagModal = React.lazy(() => import('../../modals/applications/ToggleFlagModal'))


function ApplicationPreview() {
    dayjs.extend(LocalizedFormat)

    const [openAssignModal, setopenAssignModal] = useState<boolean>(false)
    const [userAssignType, setuserAssignType] = useState<string>()
    const [userAssignData, setuserAssignData] = useState<CompleteServiceProps>()
    const [openPrefillDocumentModal, setopenPrefillDocumentModal] = useState<boolean>(false);
    const [openFlagModal, setopenFlagModal] = useState<boolean>(false)
    const [openFlagHistoryModal, setopenFlagHistoryModal] = useState<boolean>(false)
    const [selectedDocumentData, setselectedDocumentData] = useState<FormDefinitionProps>()
    const [applicationDetails, setapplicationDetails] = useState<any>([])
    const [previewApplicationDetails, setpreviewApplicationDetails] = useState<{ [key:string]: any }>({
        companyInformation: null,
        businessInformation: null,
        proprietorInformation: null,
        shareCapital: null,
        ceoInformation: null,
        cfoInformation: null,
        presidentInformation: null,
        secretaryInformation: null,
        shareholderInformation: null,
        directorInformation: null,
        beneficialOwnerInformation: null,
        additionalComments: null,
        addons: [],
        trademarkInformation: null,
        signatoryInformation: null,
        generalBucketInformation: null,
        partnersInformation: null,
        ownersInformation: null,
        auditorInformation: null,
        managerInformation: null
    })
    const [userPhoneNumber, setuserPhoneNumber] = useState<string>('')
    const [services, setservices] = useState([])
    const [specificService, setspecificService] = useState<ServiceTimelinesInterface>()
    const [currentDocIndex, setCurrentDocIndex] = useState(-1);

    const [searchParams] = useSearchParams();

    let { id } = useParams();
    const location = useLocation()
    const queryClient = useQueryClient()
    const navigate = useNavigate()
    const path = location?.pathname?.split('/')[1]

    // --------------------GET APPLICATION DATA(INFRA)-----------------------------//
    const { isError, isLoading, data } = useApplicationDetailsData()

    // -----------------------GET APPLICATION DETAILS(OPERATIONS)--------------------------//
    const { data:info } = useApplicationInfoData({ enable: !!id && (!!searchParams.get('customerEmail') || !!location?.state?.customerEmail) && (path !== 'unpaidapplication')})

    //-------------------------------GET APPLICATION DOCUMENTS-----------------------------------//
    const { data: documents } = useApplicationDocumentsData()


    //--------------------------------------GET APPLICANT PROFILE INFORMATION --------------------------------//
    const { data: user } = useQuery({
        queryKey: ['get-user-by-id', data?.userId],
        queryFn: () => fetchUserById({ userId: data?.userId ?? searchParams.get('userId')}),
        staleTime: 0,
        enabled: !!data?.userId || !!searchParams.get('userId') || false,
    })

    // --------------------------------------------MARK TRANSACTION AS PAID----------------------------------------------------------------//
    const markAsPaidMutation = useMutation({
        mutationFn: (data:IDInterface) => markAsPaid(data),
        onError: () => {
            toast.error("Mark as paid unsuccessful", {
                toastId: "unsuccessful-mark-as-paid"
            });
        },
        onSuccess: (data) => {
            queryClient.invalidateQueries({ queryKey: ['application-preview-details'] })
            queryClient.invalidateQueries({ queryKey: ['reporting-all-applications'] })
            queryClient.invalidateQueries({ queryKey: ['notifications'] })
            queryClient.invalidateQueries({ queryKey: ['operations-notifications'] })
            queryClient.invalidateQueries({ queryKey: ['applications'] })
            toast.success("Mark as paid successful", {
                toastId: "successful-mark-as-paid"
            });
        },
    })

    const handleMarkAsPaid = () => {
        const markaspaiddata = {
            applicationId: data?.id,
            companyId: data?.companyId,
        }
        markAsPaidMutation.mutate(markaspaiddata)
    }

    // --------------------------------INITIALIZE APPLICATION-----------------------------------------------------//
    const initializeMutation = useMutation({
        mutationFn: (data:DetailsInterface) => initializeApplication(data),
        onError: () => {
            toast.error("Application initialization unsuccessful", {
                toastId: "unsuccessful-initialization"
            });
        },
        onSuccess: (data) => {
            queryClient.invalidateQueries({ queryKey: ['application-preview-details'] })
            queryClient.invalidateQueries({ queryKey: ['reporting-all-applications'] })
            queryClient.invalidateQueries({ queryKey: ['applications'] })
            toast.success("Application initialization successful", {
                toastId: "successful-initialization"
            });
            navigate(
                `/applications/${data.id}?companyId=${data?.metaData?.companyId}&customerEmail=${data.customerEmail}`, 
                { 
                    state: { 
                        customerEmail: data.customerEmail,
                        companyId: data?.metaData?.companyId
                    } 
                }
            )
        },
    })

    const handleInitialize = () => {
        const initializedata = {
            applicationId: data?.id,
            customerEmail: data?.applicantEmail,
        }
        initializeMutation.mutate(initializedata)
    }

    //-----------------------------------SET ALL APPLICATION DETAILS DATA---------------------------------------//
    useEffect(() => {
        if (data?.applicationData) {
            setapplicationDetails(data?.applicationData)
            setpreviewApplicationDetails(
                {
                    companyInformation: data?.applicationData?.CompanyInformation ?? data?.applicationData?.companyInformation,
                    businessInformation: null,
                    proprietorInformation: null,
                    shareCapital: data?.applicationData?.shareCapital ?? data?.applicationData?.ShareCapital,
                    ceoInformation: data?.applicationData?.CoopInformation?.CEOInformation ?? data?.applicationData?.coopInformation?.ceoInformation,
                    cfoInformation: data?.applicationData?.CoopInformation?.CFOInformation ?? data?.applicationData?.coopInformation?.cfoInformation,
                    presidentInformation: data?.applicationData?.CoopInformation?.PresidentInformation ?? data?.applicationData?.coopInformation?.presidentInformation,
                    secretaryInformation: null,
                    shareholderInformation: data?.applicationData?.CoopInformation?.ShareholderInformation ?? data?.applicationData?.coopInformation?.shareholderInformation 
                        ?? data?.applicationData?.LLCInformation?.ShareholderInformation ?? data?.applicationData?.shareholders ?? data?.applicationData?.Shareholders
                        ?? data?.applicationData?.llcInformation?.shareholderInformation,
                    directorInformation: data?.applicationData?.directors ?? data?.applicationData?.Directors ?? data?.applicationData?.coopInformation?.directorInformation,
                    beneficialOwnerInformation: data?.applicationData?.beneficialOwners,
                    additionalComments: data?.applicationData?.additionalComments?.additionalComments,
                    addons: data?.addons,
                    trademarkInformation: null,
                    signatoryInformation: data?.applicationData?.BankAccountOpeningDetails ?? data?.applicationData?.BankAccountOpeningForm,
                    generalBucketInformation: data?.applicationData?.generalBucket,
                    partnersInformation: null,
                    ownersInformation: data?.applicationData?.owners,
                    auditorInformation: null,
                    managerInformation: data?.applicationData?.manager,
                    countryTrademarkClassifications: data?.applicationData?.countryTrademarkClassifications
                }
            )
            if (data?.applicationType === 'BusinessNameRegistration') {
                setpreviewApplicationDetails(prev => ({
                    ...prev,
                    businessInformation: data?.applicationData
                }))
            }
            if (data?.applicationData?.proprietorInformation?.typeOfBusiness === 'SoleProprietorship' || data?.applicationData?.ProprietorInformation?.TypeOfBusiness === 'SoleProprietorship') {
                setpreviewApplicationDetails(prev => ({
                    ...prev,
                    proprietorInformation: data?.applicationData?.proprietorInformation?.soleProprietor ?? data?.applicationData?.proprietorInformation?.SoleProprietor ?? data?.applicationData?.ProprietorInformation?.SoleProprietor
                }))
            }
            if (data?.applicationData?.proprietorInformation?.typeOfBusiness?.toLowerCase() === 'partnership') {
                setpreviewApplicationDetails(prev => ({
                    ...prev,
                    partnersInformation: data?.applicationData?.proprietorInformation?.partners ?? data?.applicationData?.proprietorInformation?.Partners ?? data?.applicationData?.ProprietorInformation?.Partners
                }))
            }
            if (data?.applicationData?.CoopInformation || data?.applicationData?.secretary) {
                setpreviewApplicationDetails(prev => ({
                    ...prev,
                    secretaryInformation: data?.applicationData?.CoopInformation?.SecretaryInformation?.individualSecretary ?? data?.applicationData?.secretary?.individualSecretary
                        ?? data?.applicationData?.CoopInformation?.SecretaryInformation?.corporateSecretary ?? data?.applicationData?.coopInformation?.secretaryInformation ?? data?.applicationData?.secretary?.corporateSecretary
                }))
            }
            if (data?.applicationType === 'Trademark') {
                setpreviewApplicationDetails(prev => ({
                    ...prev,
                    trademarkInformation: data?.applicationData
                }))
            }
            if (data?.applicationData?.auditor) {
                setpreviewApplicationDetails(prev => ({
                    ...prev,
                    auditorInformation: data?.applicationData?.auditor?.individualAuditor ?? data?.applicationData?.auditor?.corporateAuditor
                }))
            }
            setservices(info?.order?.paymentInformation)
            setuserPhoneNumber(data?.phoneNumber ?? data?.applicationData?.businessPhoneNumber ?? data?.applicationData?.companyInformation?.businessPhoneNumber ?? 'N/A')
        }
    }, [data, info])

    //---------------------------------FETCH SERVICE TIMELINES, DATA USED FOR HANDLING ASSIGNMENT-------------------------------------------------//
    const { data:serviceTimelines } = useServiceTimelineData({ enable: !!id && (!!searchParams.get('customerEmail') || !!location?.state?.customerEmail) && (path !== 'unpaidapplication') })

    useEffect(() => {
        if(serviceTimelines){
            setspecificService(serviceTimelines.filter((value:ServiceTimelinesInterface) => value.serviceName === 'Business Name Registration fee' || value.serviceName === 'Company Incorporation fee' || value.serviceName === 'Trademark')[0])
        }
    }, [serviceTimelines])

    const handleAssignPartner = () => {
        const assignData = {
            timelineId: specificService?.id!,
            applicationId: id!,
            customerEmail: location?.state?.customerEmail ?? searchParams.get('customerEmail'),
        }
        setuserAssignData(assignData)
        setuserAssignType('Reviewer')
        setopenAssignModal(!openAssignModal)
    }

    //-----------------------------PREFILL SS4 AND 8821 FORMS----------------------------------------//
    const handlePrefillDocumentModal = (type:string | number) => {
        setselectedDocumentData({
            type: type,
            applicationId: data?.id,
            customerEmail: data?.applicantEmail
        })
        setopenPrefillDocumentModal(!openPrefillDocumentModal)
    }

    const [flagType, setFlagType] = useState('')
    const handleToggleFlagModal = (type:string) => {
        setFlagType(type)
        setopenFlagModal(!openFlagModal)
    }

    const handleViewFlagModal = () => {
        setopenFlagHistoryModal(!openFlagHistoryModal)
    }

    const {data:comments} = useQuery({
        queryKey: ['comments', id],
        queryFn: () => fetchFlagHistory(id!),
        enabled: !!id,
        staleTime: 0
    });

    return (
        <div className='my-10'>
            { openFlagHistoryModal && <ViewFlagHistoryModal flagHistory={comments?.result} open={openFlagHistoryModal} onClose={() => setopenFlagHistoryModal(false)}/>}
            { openFlagModal && <ToggleFlagModal flagType={flagType} open={openFlagModal} onClose={() => setopenFlagModal(false)} />}

            <PreviewDocumentModal
                documentDescriptor={documents?.[currentDocIndex]}
                key={documents?.[currentDocIndex]?.toString()} // Use this to ensure the component gets reset when a new doc is selected.
                onClose={() => setCurrentDocIndex(-1)}
                open={currentDocIndex > -1}
                shouldFetch
            />

            { openPrefillDocumentModal && <PrefillDocumentsModal data={selectedDocumentData} open={openPrefillDocumentModal} onClose={() => setopenPrefillDocumentModal(!openPrefillDocumentModal)}/>}
            { openAssignModal && <Assign assignData={userAssignData} user={userAssignType} open={openAssignModal} onClose={() => setopenAssignModal(false)}/>}
            <h2 className='font-semibold text-lg'>Application Preview</h2>
            {
                info?.order?.customerNeedsHelpWithQuery &&
                <div className='rounded-lg mt-8 mb-4 w-fit'>
                    <p className='text-amber flex items-center gap-4'>
                        <MdError size={25} className='text-Amber' /> Customer Requires Help 
                    </p>
                </div>
            }
            {
                info?.order?.customerHelpWithQuery.length > 0 &&
                <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-5 mt-5'>
                    {
                        info?.order?.customerHelpWithQuery?.map((data:QueryHelp) => (
                            <div key={data.message} className='border border-card rounded-lg p-4'>
                                <p className='opacity-75'>{ dayjs(data?.dateCreated).format('LL')}</p>
                                <p className='mt-3'>{ data.message }</p>
                            </div>
                        ))
                    }
                </div>
            }
            {
                Object.values(applicationDetails).length === 0 && !isError && !isLoading && <NoData title='No application data on this application!'/>
            }
            {
                isLoading && <Loader className='mt-20 my-9'/>
            }
            {
                isError && !isLoading && 
                <ErrorComponent className='py-9' text='Application data failed to load!' />
            }
            {Object.values(applicationDetails)?.length > 0 &&  !isError && !isLoading && [
                <React.Fragment key={1}>
                    {/* USER INFORMATION */}
                    <div className='flex flex-col xl:flex-row xl:w-full justify-between gap-10 xl:gap-0 my-10'>
                        {
                            (info?.order?.metaData?.country !== 'USADelaware' && info?.order?.metaData?.country !== 'USAWyoming')
                            && <div className='xl:w-2/5'>
                                <h3 className='font-semibold'>Personal Information</h3>
                                <p className='opacity-75'>This is the clients personal information</p>
                            </div>
                        }
                        <div className=''>
                            {
                                (info?.order?.metaData?.country === 'USADelaware' || info?.order?.metaData?.country === 'USAWyoming') 
                                && (info?.order?.currentStatus === 'Completed' || info?.order?.currentStatus === 'InCorporated') &&
                                <div className=''>
                                    <h3 className='font-semibold'>Pre-filled Documents</h3>
                                    <p className='opacity-75'>These are documents required for filing EIN</p>
                                    <div className='mt-8'>
                                        <div className='flex items-center gap-3 my-5'>
                                            <p>SS4 Document</p>
                                            <Button onClick={() => handlePrefillDocumentModal('SS4')}>Download</Button>
                                        </div>
                                        <div className='flex items-center gap-3 my-5'>
                                            <p>8821 Document</p>
                                            <Button onClick={() => handlePrefillDocumentModal(8821)}>Download</Button>
                                        </div>
                                    </div>
                                </div>
                            }
                        </div>
                        <div className={`${(info?.order?.metaData?.country === 'USADelaware' || info?.order?.metaData?.country === 'USAWyoming') 
                        && (info?.order?.currentStatus === 'Completed' || info?.order?.currentStatus === 'InCorporated') ? 'xl:w-3/5' : 'lg:w-3/4 xl:w-full'}`}>
                            {
                                (info?.order?.metaData?.country === 'USADelaware' || info?.order?.metaData?.country === 'USAWyoming')
                                && <div className='mb-10'>
                                    <h3 className='font-semibold'>Personal Information</h3>
                                    <p className='opacity-75'>This is the clients personal information</p>
                                </div>
                            }
                            <div className={` grid grid-cols-1 md:grid-cols-2 gap-5`}>
                                <div className='grid grid-cols-2 items-center'>
                                    <div className='flex gap-3 items-center'>
                                        <img src='/icons/Profile.svg' alt={user?.firstName ?? 'Profile'}/>
                                        <span className='text-sm opacity-75'>Full name:</span>
                                    </div>
                                    {
                                        user &&
                                        <p>{`${(user?.firstName && user?.lastName) ? `${user?.firstName} ${user?.lastName}` : `${user?.displayName}` ? `${user?.displayName}` : 'N/A'}`}</p>
                                    }
                                    
                                </div>
                                <div className='grid grid-cols-2 items-center'>
                                    <div className='flex gap-3 items-center'>
                                        <img src='/icons/Category.svg' className='w-4'/>
                                        <span className='text-sm opacity-75'>Application:</span>
                                    </div>
                                    <p className='break-all'>{ info?.order?.applicationType ?? data?.applicationType }</p>
                                </div>
                                {
                                    data?.metadata?.partnership && 
                                    <div className='grid grid-cols-2 items-center'>
                                        <div className='flex gap-3 items-center'>
                                            <img src='/icons/Category.svg' className='w-4'/>
                                            <span className='text-sm opacity-75'>Partnership: </span>
                                        </div>
                                        <p className='break-all'>{ data?.metadata?.partnership }</p>
                                    </div>
                                }
                                <div className='grid grid-cols-2 items-center'>
                                    <div className='flex gap-3 items-center'>
                                        <img src='/icons/Calendar.svg'/>
                                        <span className='text-sm opacity-75'>Date Paid:</span>
                                    </div>
                                    <p>{ dayjs(data?.paymentDate).format('LL')}</p>
                                </div>
                                <div className='grid grid-cols-2 items-center'>
                                    <div className='flex gap-3 items-center'>
                                        <img src='/icons/Message.svg'/>
                                        <span className='text-sm opacity-75'>Email address:</span>
                                    </div>
                                    <p className='break-all'>{ path === 'unpaidapplication' ? data?.applicantEmail : info?.order?.customerEmail}</p>
                                </div>
                                {
                                    info?.order?.assignedTo && info?.order?.operationOrderAssignment?.length === 0 &&
                                    <div className='grid grid-cols-2 items-center'>
                                        <div className='flex gap-3 items-center'>
                                            <img src='/icons/Profile.svg'/>
                                            <span className='text-sm opacity-75'>Partner:</span>
                                        </div>
                                        {
                                            info?.order?.assignedTo === 'product@norebase.com'
                                            ? <span onClick={() => handleAssignPartner()} id="assign-partner" className='text-teal underline cursor-pointer'>Assign partner</span> 
                                            : <span onClick={() => handleAssignPartner()} id="assign-partner" className='text-teal underline cursor-pointer'>{ info?.order?.assignedTo ?? data?.assignedLawyer ?? 'N/A' }</span>
                                        }
                                    </div>
                                }
                                {
                                    info?.order?.operationOrderAssignment?.length > 0 &&
                                    <div className='grid grid-cols-2 items-center'>
                                        <div className='flex gap-3 items-center opacity-75'>
                                            <img src='/icons/Show.svg'/>
                                            <span className='text-sm'>{ info?.order?.operationOrderAssignment[0]?.role }:</span>
                                        </div>
                                        <span onClick={() => handleAssignPartner()} className='break-all text-teal underline cursor-pointer'>
                                            { info?.order?.operationOrderAssignment[0]?.assignedUserEmail }
                                        </span>
                                    </div>
                                }
                                <div className='grid grid-cols-2 items-center'>
                                    <div className='flex gap-3 items-center opacity-75'>
                                        <img src='/icons/Location.svg'/>
                                        <span className='text-sm'>Phone:</span>
                                    </div>
                                    <p>{ userPhoneNumber }</p>
                                </div>
                                <div className='grid grid-cols-2 items-center'>
                                    <div className='flex gap-3 items-center opacity-75'>
                                        <img src='/icons/Location.svg'/>
                                        <span className='text-sm'>Country:</span>
                                    </div>
                                    <p>{ info?.order?.metaData?.country ?? data?.country }</p>
                                </div>
                                <div className='grid grid-cols-2 items-center'>
                                    <div className='flex gap-3 items-center opacity-75'>
                                        <img src='/icons/Show.svg'/>
                                        <span className='text-sm'>Status:</span>
                                    </div>
                                    <Badge className='w-fit break-all' value={info?.order?.currentStatus ?? data?.status} status={info?.order?.currentStatus ?? data?.status}/>
                                </div>
                                <div className='grid grid-cols-2 items-center'>
                                    <div className='flex gap-3 items-center opacity-75'>
                                        <img src='/icons/Location.svg'/>
                                        <span className='text-sm'>Source:</span>
                                    </div>
                                    <p>{ data?.companyName }</p>
                                </div>
                                {
                                    path !== 'unpaidapplication' &&
                                    <div className='grid grid-cols-2 items-center'>
                                        <div className='flex gap-3 items-center opacity-75'>
                                            <BsFlag/>
                                            <span className='text-sm'>Flag:</span>
                                        </div>
                                        <div>
                                            <p onClick={() => handleToggleFlagModal('query')} className='underline decoration-teal text-teal cursor-pointer'>
                                                Add Flag
                                            </p>
                                            {
                                                comments?.result?.length > 0 &&
                                                <p onClick={handleViewFlagModal} className='underline decoration-teal text-teal cursor-pointer'>
                                                    View Flag History
                                                </p>
                                            }
                                            
                                        </div>
                                    </div>
                                }
                                {
                                    (data?.status === 'Submitted')  && path === 'unpaidapplication' &&
                                    <div className='grid grid-cols-2 items-center'>
                                        <div className='flex gap-3 items-center opacity-75'>
                                            <img src='/icons/Profile.svg'/>
                                            <span className='text-sm'>Action:</span>
                                        </div>
                                        <Button onClick={handleMarkAsPaid}>
                                            {
                                                markAsPaidMutation.isLoading ? <Loader color='stroke-white' /> : 'Mark as paid'
                                            }
                                        </Button>
                                    </div>
                                }
                                {
                                    (data?.status === 'Submitted' || data?.status === 'Paid') && path === 'unpaidapplication' &&
                                    <div className='grid grid-cols-2 items-center'>
                                        <div className='flex gap-3 items-center opacity-75'>
                                            <img src='/icons/Profile.svg'/>
                                            <span className='text-sm'>Action:</span>
                                        </div>
                                        <div className='flex items-center gap-2'>
                                            <Button className='' onClick={handleInitialize}>
                                                {
                                                    initializeMutation.isLoading ? <Loader color='stroke-white' /> : 'Initialize'
                                                }
                                            </Button>
                                            
                                            <Tippy content={
                                                <div className='bg-scheme p-4 rounded-md shadow'>
                                                    <p className='text-sm'>By default this operations portal only shows applications that are paid for. Initializing an application will move a submitted/paid/inprogress application that isn't yet on the portal into the operations portal. </p>
                                                </div>
                                            } placement='bottom'>
                                                <div>
                                                    <IoIosInformationCircleOutline className='cursor-pointer opacity-75' size={25}/>
                                                </div>
                                            </Tippy>
                                        </div>
                                    </div>
                                }
                                {
                                    (info?.order?.currentStatus === 'Completed') &&
                                    <div>
                                        <div className='grid grid-cols-2 items-center mb-5'>
                                            <div className='flex gap-3 items-center opacity-75'>
                                                <img src='/icons/Show.svg'/>
                                                <span className='text-sm'>Completed By Operations:</span>
                                            </div>
                                            <p>{ info?.order?.metaData?.completedOperationsBy}</p>
                                        </div>
                                        <div className='grid grid-cols-2 items-center mb-5'>
                                            <div className='flex gap-3 items-center opacity-75'>
                                                <img src='/icons/Show.svg'/>
                                                <span className='text-sm'>Completed By Operations Date:</span>
                                            </div>
                                            <p>{ dayjs(info?.order?.metaData?.completedOperationsDate).format('DD/MM/YYYY hh:mm:ss A') }</p>
                                        </div>
                                        <div className='grid grid-cols-2 items-center mb-5'>
                                            <div className='flex gap-3 items-center opacity-75'>
                                                <img src='/icons/Show.svg'/>
                                                <span className='text-sm'>Completed By Partner:</span>
                                            </div>
                                            <p>{ info?.order?.metaData?.completedPartnerBy}</p>
                                        </div>
                                        <div className='grid grid-cols-2 items-center mb-5'>
                                            <div className='flex gap-3 items-center opacity-75'>
                                                <img src='/icons/Show.svg'/>
                                                <span className='text-sm'>Completed By Partner Date:</span>
                                            </div>
                                            <p>{ dayjs(info?.order?.metaData?.completedPartnerDate).format('DD/MM/YYYY hh:mm:ss A')}</p>
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>

                    {/* SERVICES INFORMATION */}
                    {
                        services &&
                        <div className='flex flex-col lg:w-full gap-10 border-t border-card py-10'>
                            <div className=''>
                                <h3 className='font-semibold'>Services Information</h3>
                                <p className='opacity-75'>Some information on all services paid for</p>
                            </div>
                            <div className='relative z-0'>
                                <PreviewTable data={services} type='services'/>
                            </div>
                        </div>
                    }

                    {/* COMPANY INCORPORATION APPLICATION INFORMATION */}
                    {
                        previewApplicationDetails?.companyInformation && <DetailsCard nested={true} title='Company Information' cardData={previewApplicationDetails?.companyInformation} applicationDetails={applicationDetails} />
                    }

                    {/* TRADEMARK INFORMATION */}
                    {
                        previewApplicationDetails?.trademarkInformation && <DetailsCard title='Trademark Information' cardData={previewApplicationDetails?.trademarkInformation} applicationDetails={applicationDetails}/>
                    }

                    {/* GENERAL BUCKET INFORMATION */}
                    {
                        previewApplicationDetails?.generalBucketInformation && <DetailsCard title='Company Information (General Bucket)' cardData={previewApplicationDetails?.generalBucketInformation} applicationDetails={applicationDetails}/>
                    }

                    {/* SHARE CAPITAL INFORMATION */}
                    {
                        previewApplicationDetails?.shareCapital && <DetailsCard title='Share Capital Information' cardData={previewApplicationDetails?.shareCapital} applicationDetails={applicationDetails}/>
                    }

                    {/* CEO INFORMATION - USA INC */}
                    {
                        previewApplicationDetails?.ceoInformation && <DetailsCard nested={true} title='CEO Information' cardData={previewApplicationDetails?.ceoInformation} applicationDetails={applicationDetails}/>
                    }

                    {/* CFO INFORMATION - USA INC */}
                    {
                        previewApplicationDetails?.cfoInformation && <DetailsCard nested={true} title='CFO Information' cardData={previewApplicationDetails?.cfoInformation} applicationDetails={applicationDetails}/>
                    }

                    {/* PRESIDENT INFORMATION - USA INC */}
                    {
                        previewApplicationDetails?.presidentInformation && <DetailsCard nested={true} title='President Information' cardData={previewApplicationDetails?.presidentInformation} applicationDetails={applicationDetails}/>
                    }

                    {/* AUDITOR INFORMATION */}
                    {
                        previewApplicationDetails?.auditorInformation && <DetailsCard nested={true} title='Auditor Information' cardData={previewApplicationDetails?.auditorInformation} applicationDetails={applicationDetails}/>
                    }

                    {/* SECRETARY INFORMATION */}
                    {
                        previewApplicationDetails?.secretaryInformation && <DetailsCard nested={true} title='Secretary Information' cardData={previewApplicationDetails?.secretaryInformation} applicationDetails={applicationDetails}/>
                    }

                    {/* MANAGER INFORMATION */}
                    {
                        previewApplicationDetails?.managerInformation && <DetailsCard nested={true} title='Manager Information' cardData={previewApplicationDetails?.managerInformation} applicationDetails={applicationDetails}/>
                    }

                    {/* BUSINESS NAME REGISTRATION */}
                    {
                        previewApplicationDetails?.businessInformation && <DetailsCard title='Business Information' cardData={previewApplicationDetails?.businessInformation} applicationDetails={applicationDetails}/>
                    }

                    {/* PROPRIETORSHIP INFORMATION - BUSINESS NAME */}

                    {previewApplicationDetails?.proprietorInformation ? (
                        <DetailsCard
                            nested={true}
                            title='Proprietor Information'
                            cardData={previewApplicationDetails?.proprietorInformation}
                            applicationDetails={applicationDetails}
                        />
                    ) : null}

                    {/* ADDONS */}
                    {
                        previewApplicationDetails?.addons?.length > 0 &&
                        <div className='flex flex-col xl:flex-row lg:w-full justify-between gap-10 xl:gap-0 border-t border-card py-10'>
                            <div className='lg:w-2/5'>
                                <h3 className='font-semibold'>Addons</h3>
                                <p className='opacity-75'>Use this to file the application</p>
                            </div>
                            <div className=' lg:w-3/5'>
                                <div className='grid grid-cols-1 gap-5'>
                                    {
                                        previewApplicationDetails?.addons?.map((value: { description: string, name: string}) => (
                                            <div key={value.name}>
                                                <span className='text-sm opacity-75'>{ value.name }</span>
                                                <p className='w-full break-all'>{ value.description }</p>
                                            </div>
                                        ))
                                    }
                                </div>
                            </div>
                        </div>
                    }

                    {/* COUNTRY CLASSIFICATIONS */}
                    {
                        previewApplicationDetails?.countryTrademarkClassifications?.length > 0 &&
                        <div className='flex flex-col xl:flex-row lg:w-full justify-between gap-10 xl:gap-0 border-t border-card py-10'>
                            <div className='lg:w-2/5'>
                                <h3 className='font-semibold'>Country Classifications</h3>
                                <p className='opacity-75'>Use this to file the application</p>
                            </div>
                            <div className=' lg:w-3/5'>
                                <div className='grid grid-cols-1 gap-5'>
                                    {
                                        previewApplicationDetails?.countryTrademarkClassifications?.map((value: { country: string, trademarkClassifications: string[]}) => (
                                            <div key={value.country}>
                                                <span className='text-sm opacity-75'>{ value.country }</span>
                                                <p className='w-full break-all'>{ value.trademarkClassifications.join(',') }</p>
                                            </div>
                                        ))
                                    }
                                </div>
                            </div>
                        </div>
                    }

                    {/* SHAREHOLDER INFORMATION */}
                    {
                        previewApplicationDetails?.shareholderInformation &&
                        <div className='flex flex-col lg:w-full gap-10 border-t border-card py-10'>
                            <div className=''>
                                <h3 className='font-semibold'>Shareholder Information</h3>
                                <p className='opacity-75'>Some shareholder information for the clients application</p>
                            </div>
                            <div className='relative z-0'>
                                <PreviewTable data={previewApplicationDetails?.shareholderInformation ?? []} type='shareholder'/>
                            </div>
                        </div>
                    }

                    {/* DIRECTOR INFORMATION */}
                    {
                        previewApplicationDetails?.directorInformation &&
                        <div className='flex flex-col lg:w-full gap-10 border-t border-card py-10'>
                            <div className=''>
                                <h3 className='font-semibold'>Director Information</h3>
                                <p className='opacity-75'>Some director information for the clients application</p>
                            </div>
                            <div className='relative z-0'>
                                <PreviewTable data={previewApplicationDetails?.directorInformation ?? []} type='director'/>
                            </div>
                        </div>
                    }

                    {/* BENEFICIAL OWNER INFORMATION */}
                    {
                        previewApplicationDetails?.beneficialOwnerInformation &&
                        <div className='flex flex-col lg:w-full gap-10 border-t border-card py-10'>
                            <div className=''>
                                <h3 className='font-semibold'>Beneficial Information</h3>
                                <p className='opacity-75'>Some beneficial owner information for the clients application</p>
                            </div>
                            <div className='relative z-0'>
                                <PreviewTable data={previewApplicationDetails?.beneficialOwnerInformation ?? []} type='beneficialOwner'/>
                            </div>
                        </div>
                    }

                    {/* SIGNATORY INFORMATION */}
                    {
                        data?.applicationData?.BankAccountOpeningDetails && previewApplicationDetails?.signatoryInformation &&
                        <div className='flex flex-col lg:w-full gap-10 border-t border-card py-10'>
                            <div className=''>
                                <h3 className='font-semibold'>Signatory Information</h3>
                                <p className='opacity-75'>Some signatory information for the clients application</p>
                            </div>
                            <div className='relative z-0'>
                                <PreviewTable data={previewApplicationDetails?.signatoryInformation ?? []} type='signatory'/>
                            </div>
                        </div>
                    }

                    {
                        data?.applicationData?.BankAccountOpeningForm && previewApplicationDetails?.signatoryInformation?.CompanyProfile &&
                        <div className='flex flex-col xl:flex-row lg:w-full justify-between gap-10 xl:gap-0 border-t border-card py-10'>
                            <div className='lg:w-2/5'>
                                <h3 className='font-semibold'>Signatory Company Profile</h3>
                                {/* <p className='opacity-75'>Use this to file the application</p> */}
                            </div>
                            <div className=' lg:w-3/5'>
                                <div className='grid md:grid-cols-2 gap-5'>
                                    {
                                        Object.entries(previewApplicationDetails?.signatoryInformation?.CompanyProfile)?.map(([key, value], index) => (
                                            value !== '' && value !== null &&
                                                <div key={key}>
                                                    <span className='text-sm opacity-75'>{ addSpaceToTitle(key) }</span>
                                                    <p className='w-full break-all'>{ value!.toString() }</p>
                                                </div>
                                        ))
                                    }
                                    <div>
                                        <span className='text-sm opacity-75'>Bank Name</span>
                                        <p className='w-full break-all'>{ previewApplicationDetails?.signatoryInformation?.BankName }</p>
                                    </div>
                                    <div>
                                        <span className='text-sm opacity-75'>Signing Mandate</span>
                                        <p className='w-full break-all'>{ previewApplicationDetails?.signatoryInformation?.SigningMandate }</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }

                    {
                        data?.applicationData?.BankAccountOpeningForm && previewApplicationDetails?.signatoryInformation?.SignatoryInformation &&
                        <div className='flex flex-col xl:flex-row lg:w-full justify-between gap-10 xl:gap-0 border-t border-card py-10'>
                            <div className='lg:w-2/5'>
                                <h3 className='font-semibold'>Signatories</h3>
                            </div>
                            <div className=' lg:w-3/5'>
                                <div className='grid md:grid-cols-2 gap-5'>
                                    {
                                        Object.entries(previewApplicationDetails?.signatoryInformation?.SignatoryInformation)?.map(([key, value], index) => (
                                            value !== '' && value !== null &&
                                                <div key={key}>
                                                    <span className='text-sm opacity-75'> Signatory Name </span>
                                                    <p className='w-full break-all'>{ value!['SignatoryName' as keyof typeof value] }</p>
                                                </div>
                                        ))
                                    }
                                </div>
                            </div>
                        </div>
                    }
                    {
                        data?.applicationData?.BankAccountOpeningForm && previewApplicationDetails?.signatoryInformation?.DirectorInformation &&
                        <div className='flex flex-col lg:w-full gap-10 border-t border-card py-10'>
                            <div className=''>
                                <h3 className='font-semibold'>Signatory Director Information</h3>
                                <p className='opacity-75'>Some signatory director information for the clients application</p>
                            </div>
                            <div className='relative z-0'>
                                <PreviewTable data={previewApplicationDetails?.signatoryInformation?.DirectorInformation ?? []} type='signatory'/>
                            </div>
                        </div>
                    }

                    {/* PARTNERS INFORMATION */}
                    {
                        previewApplicationDetails?.partnersInformation &&
                        <div className='flex flex-col lg:w-full gap-10 border-t border-card py-10'>
                            <div className=''>
                                <h3 className='font-semibold'>Partners Information</h3>
                                <p className='opacity-75'>Some partners information for the clients application</p>
                            </div>
                            <div className='relative z-0'>
                                <PreviewTable data={previewApplicationDetails?.partnersInformation ?? []} type='partners'/>
                            </div>
                        </div>
                    }

                    {/* OWNERS INFORMATION */}
                    {
                        previewApplicationDetails?.ownersInformation &&
                        <div className='flex flex-col lg:w-full gap-10 border-t border-card py-10'>
                            <div className=''>
                                <h3 className='font-semibold'>Owners Information</h3>
                                <p className='opacity-75'>Some owners information for the clients application</p>
                            </div>
                            <div className='relative z-10'>
                                <PreviewTable data={previewApplicationDetails?.ownersInformation ?? []} type='owners'/>
                            </div>
                        </div>
                    }

                     {/* DOCUMENTS */}
                </React.Fragment>,
                <React.Fragment key={2}>
                    {(documents?.length || 0) > 0 ? (
                        <div className='flex flex-col lg:w-full justify-between gap-10 border-t border-card py-10'>
                            <div className='lg:w-2/5'>
                                <h3 className='font-semibold'>Documents</h3>
                                <p className='opacity-75'>Use this to file the application</p>
                            </div>

                            <div>
                                <div className='flex flex-wrap gap-8'>
                                    {documents?.map((docDetails, index: number) => {
                                        const startApiDocInfo = 'attachmentUrl' in docDetails ? docDetails : null;
                                        const opsApiDocInfo = 'documentUrl' in docDetails ? docDetails : null;
                                        return (
                                            <div className='h-[320px] max-w-[300px] p-4 flex flex-col justify-between items-center border border-card rounded-md text-center overflow-auto'
                                                    key={`${startApiDocInfo?.attachmentUrl || opsApiDocInfo?.documentUrl}|${index}`}>
                                                <p className='break-all mx-auto mt-3 font-bold'>
                                                    {startApiDocInfo?.documentType ?? opsApiDocInfo?.documentTitle}
                                                </p>
                                                <p className='break-all mx-auto text-sm mt-3'>
                                                    {startApiDocInfo?.documentName ?? opsApiDocInfo?.originalFileName}
                                                    <br /><br />
                                                    {startApiDocInfo?.description ?? ''}
                                                </p>
                                                <div className='py-5 flex justify-center'>
                                                    <Button onClick={() => setCurrentDocIndex(index)/* () => handleDownload(img) */}>
                                                        Open
                                                    </Button>
                                                </div>
                                            </div>
                                        );
                                    })}
                                </div>
                            </div>
                        </div>
                    ) : null}

                    {/* ADDITIONAL COMMENTS INFORMATION */}
                    {previewApplicationDetails?.additionalComments ? (
                        <div className='flex flex-col lg:w-full gap-10 border-t border-card py-10'>
                            <div className=''>
                                <h3 className='font-semibold'>Additional Comments</h3>
                                <p className='opacity-75'>Some extra information for the clients application</p>
                            </div>
                            <div className='break-all border border-card rounded-md px-6 py-5'>
                                { previewApplicationDetails?.additionalComments }
                            </div>
                        </div>
                    ) : null}
                </React.Fragment>
            ]}
        </div>
    )
}

export default ApplicationPreview

