type TDeepEdit = <SourceType = any>(
	source: SourceType,
	newValue: any,
	namepath: string
) => SourceType;

type TDeepAccess = <TargetType>(
	source: any,
	namepath: string
) => TargetType;

export const setValueAtNamepath: TDeepEdit = (source, newValue, namepath) => {
	const pathSegments = namepath.split('.');
	const lastIndex = pathSegments.length - 1;
	const lastSegment = pathSegments[lastIndex];

	let immediateParent: any = source;

	pathSegments.forEach((segment, index) => {
		// Skip the last segment, because we want the target property's direct parent.
		if (index === lastIndex) return;

		immediateParent = immediateParent?.[segment];
	});

	// Assigning the final value through it's direct parent allows us
	// to mutate the source object because the keys on the parent
	// reference the values on the original source object.
	// This would not be possible if we extracted the final value itself,
	// since then we would only be mutating a local variable.
	if (immediateParent) immediateParent[lastSegment] = newValue;
	return source;
};

export const getValueAtNamepath: TDeepAccess = (source, namepath) => {
	const pathSegments = namepath.split('.');

	let extractedValue = source;

	pathSegments.forEach((segment) => {
		extractedValue = extractedValue?.[segment];
	});

	return extractedValue;
};
