import { msalInstance } from "./azureAdB2cService/msalSetup";
import { createGuid } from './constant';
import { loginRequest } from './azureAdB2cService/azureAdB2cServiceConfig';
import { addBreadcrumb, setUser, captureException, setContext } from '@sentry/react'
import { jwtDecode } from 'jwt-decode'
import { toast } from 'react-toastify';
import { loggerClient } from './LoggerClient';
import { createAxiosClient } from 'norebase-wrappers-web';

export const operationsBaseUrl = import.meta.env.VITE_OPERATIONS_API_URL;
let baseUrl = import.meta.env.VITE_INFRA_API_URL;


let CorrelationId = localStorage.getItem('correlationId') ?? ''

const onRequestSuccess = async(config: any) => {

	let authToken = localStorage.getItem('operations_access_token') || '';
	let CorrelationId = localStorage.getItem('correlationId') ?? ''
	
	
	try {
		if(!authToken){
			const account = msalInstance.getActiveAccount();
			const response = await msalInstance.acquireTokenSilent({
				...loginRequest,
				account: account!
			});
			
			authToken = response.accessToken;
			localStorage.setItem('operations_access_token', authToken);
		}
		if (!CorrelationId && config.url !== 'https://sentry.io/api/0/projects/norebase-7a/norebase-operations/user-feedback/') {
			const generatedGuid = createGuid();
			config.headers['X-Correlation-ID'] = generatedGuid;
			localStorage.setItem('correlationId', generatedGuid)
		}
	
		if (authToken) {
			let decodedAuthToken = jwtDecode(authToken);
			let exp = 0
			if(decodedAuthToken?.exp){
				exp = decodedAuthToken.exp * 1000
			}
			if(exp < 1 || Date.now() >= exp){
				localStorage.clear();
				sessionStorage.clear();
				msalInstance.logoutRedirect();
				return;
			}
			config.headers['Authorization'] = 'Bearer ' + authToken;
			config.headers['Client-Identifier'] = 'Operations-Portal'
		
			if(config.url === 'Document/upload-application-document' || config.url === 'Document/upload-document' || config.url === 'application/upload'){
				config.headers['Content-Type'] = 'multipart/form-data'
			}
			else{
				config.headers['Content-Type'] = 'application/json'
			}
		}
		if (CorrelationId && config.url !== 'https://sentry.io/api/0/projects/norebase-7a/norebase-operations/user-feedback/') {
			config.headers['X-Correlation-ID'] = CorrelationId;
		}
		return config;
	} catch (error) {
		localStorage.clear();
		sessionStorage.clear();
		return msalInstance.loginRedirect();
	}
};

const onRequestError = (err: any) => {
	const userData = JSON.parse(localStorage.getItem('userData') ?? 'null');

	const duration = Date.now() - err.config.headers['StartTime'];

	loggerClient.trackEvent('OperationsPortalRequest', { api: err.config.url, userData, headers: err.config.headers, apiTemplate:err.config.headers['ApiTemplate'], baseURL: err.config.baseURL, duration, message: err.code, resultCode: err.request.status, success:false }, CorrelationId, err.config.method)

	addBreadcrumb({
		category: 'Request Error',
		message: `method: ${err.config.method}, endpoint: ${err.config.url}`,
		data: err.response.config.data,
		level: "info",
	});

	setUser({ 
		email: userData.email, 
		userName: (!userData.firstName || !userData.lastName) ? `${userData.firstName} ${userData.lastName}` : userData.displayName,
		id: userData.userId
	});

	captureException(err)

	return Promise.reject(err);
};

interface IUserData {
	phoneNumber: string,
	dateCreated: string,
	email: string,
	firstName: string | null,
	lastName: string | null,
	role: string,
	userId: string
	displayName: string
	groups: string[]
}



const onResponseSuccess = (response: any) => {
	const userData: IUserData = JSON.parse(localStorage.getItem('userData') ?? 'null');
	const duration = Date.now() - response.config.headers['StartTime']
	loggerClient.trackEvent('OperationsPortalRequest', { api: response.config.url, userData, headers: response.config.headers, apiTemplate:response.config.headers['ApiTemplate'], baseURL: response.config.baseURL, duration, resultCode: response.status, success:true }, CorrelationId, response.config.method)
	return response
};

const onResponseError = (err: any) => {
	const userData: IUserData = JSON.parse(localStorage.getItem('userData') ?? 'null');
	const userGroups = JSON.parse(localStorage.getItem('userGroups') ?? 'null');

	console.log('response err:', err);
	const duration = Date.now() - err.config.headers['StartTime'];
	
	// HANDLE TIMEOUT EXCEEDED && NETWORK ERROR
	// if (err.code === 'ERR_BAD_REQUEST'
	if (err.code === 'ECONNABORTED' && err.message.includes('timeout')) {
		loggerClient.trackEvent('OperationsPortalRequest', { api: err.config.url, userData, headers: err.config.headers, apiTemplate:err.config.headers['ApiTemplate'], baseURL: err.config.baseURL, duration, message: err.code, resultCode: err.request.status, success:false }, CorrelationId, err.config.method)
		toast.error("Response taking too long, try again!", {
			toastId: "timeout-exceed"
		});
		return err
	}

	if (err.code === 'ERR_NETWORK') {
		loggerClient.trackEvent('OperationsPortalRequest', { api: err.config.url, userData, headers: err.config.headers, apiTemplate:err.config.headers['ApiTemplate'], baseURL: err.config.baseURL, duration, message: err.code, resultCode: err.request.status, success:false }, CorrelationId, err.config.method)
		toast.error("Network error, check your internet connection and try again!", {
			toastId: "network-error"
		});
		return err
	}

	if (err.response && err.response.status === 401) {
		// localStorage.removeItem('operations_access_token');
			localStorage.clear();
			msalInstance.logoutRedirect();
			// msalInstance.loginRedirect(loginRequest);
			return err;
	}

	loggerClient.trackEvent('OperationsPortalRequest', { api: err.config.url, userData, headers: err.config.headers, apiTemplate:err.config.headers['ApiTemplate'], baseURL: err.config.baseURL, duration, resultCode: err.request.status, success:false }, CorrelationId, err.config.method)

	addBreadcrumb({
		category: 'Response Error',
		message: `method: ${err.config.method}, endpoint: ${err.config.url}`,
		data: err.response.config.data,
		level: "info",
	});

	setUser({ 
		email: userData.email, 
		userName: (!userData.firstName || !userData.lastName) ? `${userData.firstName} ${userData.lastName}` : userData.displayName,
		id: userData.userId
	});
	

	if (err.response && err.response.status === 400) {
		setContext("Bad Request", {
			payload: JSON.stringify(JSON.parse(err?.response?.config?.data ?? 'null')),
			error: JSON.stringify(err?.response?.data),
			failingEndpoint: err?.response?.request?.responseURL,
			group: userGroups ?? 'Unknown',
		});
		captureException(err);
		return Promise.reject(err);
	}

	if (err.response && err.response.status === 404) {
		setContext("404 error", { 
			payload: JSON.stringify(JSON.parse(err?.response?.config?.data ?? 'null')),
			error: JSON.stringify(err?.response?.data),
			failingEndpoint: err?.response?.request?.responseURL,
			group: userGroups ?? 'Unknown'
		});
		captureException(err)
		return Promise.reject(err)
	}

	if (err.response && err.response.status === 500) {
		setContext("Server error", {
			payload: JSON.stringify(JSON.parse(err?.response?.config?.data ?? 'null')),
			error: JSON.stringify(err?.response?.data),
			failingEndpoint: err?.response?.request?.responseURL,
			group: userGroups ?? 'Unknown',
		});
		captureException(err);
		return Promise.reject(err);
	}

	captureException(err);

	return Promise.reject(err);
};

export const InfraAxiosInstance = createAxiosClient({
    baseURL: baseUrl,
	loggerClient:loggerClient,
	requestErrorInterceptor: onRequestError,
	requestSuccessInterceptor: onRequestSuccess,
	responseErrorInterceptor: onResponseError,
	responseSuccessInterceptor: onResponseSuccess,
	correlationId: CorrelationId,
	appName: import.meta.env.VITE_APP_NAME,
});

const OperationsAxiosInstance = createAxiosClient({
    baseURL: operationsBaseUrl,
	loggerClient:loggerClient,
	requestErrorInterceptor: onRequestError,
	requestSuccessInterceptor: onRequestSuccess,
	responseErrorInterceptor: onResponseError,  
	responseSuccessInterceptor: onResponseSuccess,
	correlationId: CorrelationId,
	appName: import.meta.env.VITE_APP_NAME,
});

export default OperationsAxiosInstance;
