/**
 * Returns the input string with the first letter of each word in uppercase,
 * and every other character in lowercase.
 * @param input - The string to be converted
 * @param forceLowerCase - Determines if words in all-caps should be forced to lowercase. <br />
 * 	If `true`, "My URL" will become "My Url". <br />
 * 	Defaults to false.
 * @returns The converted string in title case.
 */
export const toTitleCase = (input: string, forceLowerCase?: boolean) => {
	let output = input ? input.toLowerCase().split(/\s/) : [];

	output = output.map((_word) => {
		const word = _word.trim();
		if (forceLowerCase)
			return `${word.slice(0, 1).toUpperCase()}${word.slice(1).toLowerCase()}`;
		else return `${word.slice(0, 1).toUpperCase()}${word.slice(1)}`;
	});

	return output.join(' ');
};

export const sentenceFromCamelCase = (input: string) => {
	const unformattedSentence = input
		.replaceAll(/[\.\_]/g, ' ') // Replace all "." and "_" with whitespace. e.g "foo.bar_code" -> "foo bar Code".
		.replaceAll(/([a-z])([A-Z])/g, '$1 $2') // Find all occurences of lowercase followed by uppercase, and insert a space inbetween them. e.g "fooBar" -> "foo Bar".
			// 👆🏼 Alternative implementation with lookbehind.
			// Lookbehinds influence the comparison but aren't included in the match.
			// input.replaceAll(/(?<=[a-z])([A-Z])/g, '\s$1')
		.replaceAll(/([A-Z]+)([A-Z][a-z])/g, '$1 $2') // Find all occurences of all-caps abbreviations followed by another camel case segment, and insert a space inbetween them. e.g "my URLScheme" -> "my URL Scheme".
	;

	return toTitleCase(unformattedSentence);
}


declare global {
	interface String {
		toTitleCase: (this: string) => string;
	}
}
String.prototype.toTitleCase = function () {
	return this.replace(/^.*$/, (match, ...args) => { // Match the entire string, and convert it to Title Case.
		const noNamedGroups = typeof args.at(-1) === "string";
		const fullString = noNamedGroups ? args.at(-1) : args.at(-2);
		// Can also use `match` directly since the full string is matched.
		return toTitleCase(fullString);
	});
}
