import type { FunctionComponent } from 'react';


export interface ButtonInterface {
    children: React.ReactNode,
    className?: string,
    onClick?: (value?: any) => void,
    icon?: JSX.Element,
    type?: 'submit' | 'button' | 'reset' | undefined,
    theme?: string,
    disabled?: boolean,
    prepend?: boolean,
}

type TButton = FunctionComponent<ButtonInterface>;

const Button: TButton = ({ children, className, onClick, icon, type = 'button', theme, disabled = false, prepend = false }) => {
    return (
        <button className={`${className} ${disabled ? 'bg-card cursor-not-allowed' : theme ? theme : 'bg-teal text-white '} ${icon && ' flex '} items-center gap-2 rounded-md px-6 py-3`}
                disabled={disabled}
                onClick={onClick}
                type={type}>
            { prepend && icon ? icon : null}
            { children }
            { !prepend && icon ? icon : null}
        </button>
    );
}

export default Button;
