import type { FunctionComponent, ReactNode } from 'react';

import { Fragment } from 'react';
import { CiEdit } from 'react-icons/ci';

import { sentenceFromCamelCase } from '@/utils/string';


type DetailsItemsListComponent = FunctionComponent<{
	displayDeciders: {
		keysWithNestedInfo: string[],
		keysToOmitFromDisplay: string[],
	},
	cardDataState: any,
	launchEditModal: (namepath: string) => void,
	cardInfo: {
		title: string,
		editable: boolean,
	},
	nested: boolean,
}>;


type SingleDetailItemProps = {
	label: string,
	key: string,
	isReadonly: boolean,
	editable: boolean,
	value: any,
	onEditClick: VoidFunction,
}

const SingleDetailItem = (props: SingleDetailItemProps) => (
	<div>
		<dt className='text-sm opacity-75 flex gap-2 items-center'>
			{props.isReadonly ? props.label : (
				<button className='cursor-pointer text-sm opacity-75 flex gap-2 items-center'
						onClick={props.onEditClick}>
					{props.label}

					{props.editable ? (
						<Fragment>
							<span className='sr-only'>
								Edit
							</span>
							<CiEdit size={15} />
						</Fragment>
					) : null}
				</button>
			)}
		</dt>

		<dd>
			{(props.value === '' || props.value === null) ? (
				<p className='break-all font-semibold'>
					{props.isReadonly ? (
						<span className='text-darkYellow'>
							Value is null, but the field is set as readonly and cannot be editted.
						</span>
					) : (
						<span className='text-amber'>
							Value is null, enter appropriate info.
						</span>
					)}
				</p>
			) : (
				<p className='break-all'>
					{`${props.value}`}
				</p>
			)}
		</dd>
	</div>
);


const DetailsCardItemsList: DetailsItemsListComponent = (props) => {
	const { cardDataState, launchEditModal, cardInfo, nested } = props;
	const { keysWithNestedInfo, keysToOmitFromDisplay } = props.displayDeciders;
	const readonlyProps = ['cacnatureofbusinessid', 'cacspecificnatureofbusinessid'];

	if (!cardDataState) return null;
	if (!cardInfo) return null;

	type TNestedDetails = (
		outerKey: string,
		outerValue: object | string,
		keyPrefix?: string
	) => ReactNode;

	const NestedDetails: TNestedDetails = (objectKey, nestedValues, objectNamepath = objectKey) => {
		if (!nestedValues) return null;
		if (!keysWithNestedInfo.includes(objectKey)) return null;

		return Object.entries(nestedValues).map(([valueKey, value]): ReactNode => {
			const valueNamepath = `${objectNamepath}.${valueKey}`;

			const labelFromNamepath = sentenceFromCamelCase(
				valueNamepath.replaceAll('.', ' > ')
			).replaceAll(/[dD]istrict/g, 'LGA (District)');

			if (keysToOmitFromDisplay.includes(valueKey)) {
				return null;
			}

			else if (typeof value !== 'string' && `${value}` === '[object Object]') {
				return NestedDetails(
					valueKey,
					value,
					valueNamepath
				);
			}

			else return (
				<SingleDetailItem
					key={valueNamepath}
					label={labelFromNamepath}
					isReadonly={readonlyProps.includes(valueKey.toLowerCase())}
					value={value}
					onEditClick={() => launchEditModal(valueNamepath)}
					editable={cardInfo?.editable}
				/>
			);
		});
	};


	const cardDataEntries = Object.entries<any>(cardDataState);

	if (nested) return cardDataEntries.map(([key, value]) => {
		if (keysToOmitFromDisplay.includes(key)) {
			return null;
		}

		if (`${value}` === '[object Object]') return (
			<Fragment key={key}>
				{NestedDetails(key, value)}
			</Fragment>
		);

		else return (
			<SingleDetailItem
				key={key}
				label={sentenceFromCamelCase(key)}
				isReadonly={readonlyProps.includes(key.toLowerCase())}
				value={value}
				onEditClick={() => launchEditModal(key)}
				editable={cardInfo?.editable}
			/>
		);
	});

	else return cardDataEntries.map(([key, value]) => {
		if (keysToOmitFromDisplay.includes(key)) {
			return null;
		}

		else return (
			<SingleDetailItem
				key={key}
				label={sentenceFromCamelCase(key)}
				isReadonly={readonlyProps.includes(key.toLowerCase())}
				value={value}
				onEditClick={() => launchEditModal(key)}
				editable={cardInfo?.editable}
			/>
		);
	});
}

export default DetailsCardItemsList;
